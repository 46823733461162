<template>
      <!--begin::Content-->
      <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
        <!--begin::Messenger-->
        <div class="card" id="kt_chat_messenger">
          <!--begin::Card header-->
          <div class="card-header" id="kt_chat_messenger_header">
            <!--begin::Title-->
            <div class="card-title">
              <!--begin::User-->
              <div class="d-flex justify-content-center flex-column me-3">
                <a
                  href="#"
                  class="
                    fs-4
                    fw-bolder
                    text-gray-900 text-hover-primary
                    me-1
                    mb-2
                    lh-1
                  "
                  >{{sessionTitle}}</a
                >
              </div>
              <!--end::User-->
            </div>
            <!--end::Title-->
          </div>
          <!--end::Card header-->
  
          <!--begin::Card body-->
          <div class="card-body" id="kt_chat_messenger_body">
            <!--begin::Messages-->
            <div
              class="scroll-y me-n5 pe-5 h-700px"
              ref="messagesRef"
              data-kt-element="messages"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
              data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
              data-kt-scroll-offset="-2px"
            >
              <template v-for="(item, index) in messages" :key="index">
                <MessageIn
                  ref="messagesInRef"
                  v-if="item.type === 'in'"
                  :name="item.name"
                  :image="item.image"
                  :time="item.time"
                  :text="item.text"
                ></MessageIn>
                <MessageOut
                  ref="messagesOutRef"
                  v-if="item.type === 'out'"
                  name="Sen"
                  :image="item.image"
                  :time="item.time"
                  :text="item.text"
                  :emotionAnalysis="item.emotionAnalysis"
                ></MessageOut>
              </template>
            </div>
            <!--end::Messages-->
          </div>
          <!--end::Card body-->
  
          <!--begin::Card footer-->
          <div class="card-footer pt-4" id="kt_chat_messenger_footer">
            <!--begin:Toolbar-->
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                text-align: center;
                padding-top: 13px;
              "
            >
            {{ $t("modals.hotel.closedSession") }}
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card footer-->
        </div>
        <!--end::Messenger-->
      </div>
      <!--end::Content-->
    </template>

    
  <script lang="ts">
  import { defineComponent, onMounted, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import { ScrollComponent } from "../../../assets/ts/components";
  import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
  import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
  import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
  import { SessionListModel } from "@/domain/hotelManagement/sessionList/model/SessionListModel";
  import { useStore } from "vuex";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import { useI18n } from "vue-i18n";
import { EmotionAnalysisModel } from "@/domain/hotelManagement/sendMessage/model/EmotionAnalysisModel";
import i18n from "@/presentation/language/i18n";
  
  interface KTMessage {
    type: string;
    name?: string;
    image: string;
    time: string;
    text: string;
    emotionAnalysis?: any;
  }
  
  export default defineComponent({
    name: "private-chat",
    components: {
      MessageIn,
      MessageOut,
    },
    setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
  
      const sessionId = ref<any>(Number(route.params["id"]));
  
      const messagesRef = ref<null | HTMLElement>(null);
      const messagesInRef = ref<null | HTMLElement>(null);
      const messagesOutRef = ref<null | HTMLElement>(null);
  
      const swalNotification = new SwalNotification();
  
      const messages = ref<Array<KTMessage>>([]);
      const sessionTitle = ref<string | undefined>();
      const sessionList = ref<SessionListModel[]>([]);
      const sessionListModel: SessionListModel = {
        session: {
        productSessionMessages: []
      },
        page: 1,
        pageSize: 50,
      };
  
      const sessionController = store.state.ControllersModule.sessionController;
  
      const newMessageText = ref("");
  
      watch(
        () => route.params["id"],
        (newValue, oldValue) => {
            if(newValue != oldValue)
            getSessionHistory();
        }
      );

      const getSessionList = () => {
        sessionList.value = [];
        messages.value = [];
  
        sessionController
          .sessionList(sessionListModel)
          .then((response) => {
            if (response.isSuccess) {
              response.getValue().forEach((session) => {
                sessionList.value.push(session);
                if (session.session.id == sessionId.value) {
                  sessionTitle.value = session.session.title
                  session.session.productSessionMessages.forEach((log, index) => {
                    messages.value.push({
                      type: log.owner === "USER" ? "out" : "in",
                      name: log.owner === "USER" ? undefined : "Asistan",
                      image:
                        log.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
                      time: formatDate(log.createdAt),
                      text: log.text,
                      emotionAnalysis: log.emotionAnalysis
                    });
                  });
                }
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };

      const getSessionHistory = () => {
        messages.value = [];

        sessionList.value.forEach((session) => {
                if (session.session.id == Number(route.params["id"])) {
                  sessionTitle.value = session.session.title
                  if(session.session.productSessionMessages)
                  session.session.productSessionMessages.forEach((log, index) => {
                    messages.value.push({
                      type: log.owner === "USER" ? "out" : "in",
                      name: log.owner === "USER" ? undefined : "Asistan",
                      image:
                        log.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
                      time: formatDate(log.createdAt),
                      text: log.text,
                      emotionAnalysis: log.emotionAnalysis
                    });
                  });
                }
              });
      }
  
      const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const now = new Date();
  
        const difference = now.getTime() - date.getTime();
  
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  
        if (days === 0) {
          const hours = Math.floor(difference / (1000 * 60 * 60));
          if (hours === 0) {
            const minutes = Math.floor(difference / (1000 * 60));
            return `${minutes} ` + i18n.global.t("common.times.minuteAgo");
          } else {
            return `${hours} ` + i18n.global.t("common.times.hourAgo");
          }
        } else {
          return `${days} ` + i18n.global.t("common.times.dayAgo");
        }
      };
  
      onMounted(() => {
        getSessionList();
        setCurrentPageTitle(t("modals.hotel.title"));
  
        if (!messagesRef.value) {
          return;
        }
  
        ScrollComponent.bootstrap();
      });
  
      return {
        formatDate,
        messages,
        messagesRef,
        newMessageText,
        messagesInRef,
        messagesOutRef,
        sessionList,
        sessionTitle,
      };
    },
  });
  </script>
  